import { bool, node, number, object, oneOfType, string } from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom"

import {
    acceptBookingInvitation,
    apiError,
    banSupersub,
    bookingConfirm,
    bookingConfirmCompo,
    bookingPaymentConfirm,
    bookingSuccess,
    bookingWithoutPaying,
    cancelPayment,
    closeSupersubRequest,
    freeSupersubConfirm,
    organizerAcceptSupersub,
    organizerRefuseSupersub,
    playerSupersubConfirm,
    preregisterConfirm,
    supersubCreationConfirm,
} from "./confirmCommandMessage"
import { cupConfirm, leagueConfirm, leagueSharePaymentConfirm, trainingConfirm } from "./confirmCommandSubtitle"
import ButtonCta from "../../../../../components/Buttons/button/button-cta"
import CompositionField from "../../../../../components/CompositionField/CompositionField"
import playersNumber from "../../../../../components/reservation/LocationBlock/playersNumber"
import ShareLink from "../../../../../components/ShareLink/ShareLink"
import { useTheme } from "../../../../../components/ThemeContext/ThemeContext"
import { getFactures, getLocation, getUser } from "../../../../../globalAPI/api"
import useUrbanLanguage from "../../../../../hooks/urbanLanguage/useUrbanLanguage"
import { useDidomiEcommerceEvent } from "../../../../../hooks/useDidomiDataLayer.js"
import useMediaPath from "../../../../../hooks/useMediaPath"
import useResetScroll from "../../../../../hooks/useResetScroll"
import useThemeFeatures from "../../../../../hooks/useThemeFeatures"
import { getKidsSchoolSubscription } from "../../../../../pages/kids/ecoleDeFoot/api.js"
import BookingInvitation from "../../../../../pages/reservation/infoReservation/components/BookingInvitation/BookingInvitation"
import { useLocationStore } from "../../../../../pages/reservation/store/store.js"
import ModalHandler from "../../../../../providers/Modal/ModalHandler"
import { LocationPaths } from "../../../../../routes/ApplicationPaths/locationPaths.js"
import usePath from "../../../../../routes/services/usePath"
import { buildDidomiEcommerceData } from "../../../../../services/didomiService.js"
import { apiLog } from "../../../../../services/errorHandler.js"
import generateShareLink from "../../../../../services/generateShareLink"
import { hasUnpaidBills } from "../../../../../store/actions/content"
import { clearAllForms } from "../../../../../store/forms/actions"
import { loadUrlQueryParameters } from "../../../actions/scaffold"

import "./ConfirmationCmd.scss"

function ConfirmationCmd({
    success, title, subtitle, message, confirmedSubscription, secondSubscription, tunnelType,
    isCanceled, customLink, customContentIdentifier, doRefresh, fromGestion, locationId,
    preBookingId, bookingId, noInvit, noThanks, underThanks, krAnswer,
}) {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const mediaPath = useMediaPath()
    const theme = useTheme()
    const themeFeatures = useThemeFeatures()
    const { tu } = useUrbanLanguage()
    const path = usePath()
    const { clearStore, setCurrentLocation } = useLocationStore()
    const { trackEcommerceEvent } = useDidomiEcommerceEvent()

    const user = useSelector(state => state.userLoaded)

    const [ customMessage, setCustomMessage ] = useState("")
    const [ customSubtitle, setCustomSubtitle ] = useState("")
    const [ searchParams ] = useSearchParams()
    const [ booking, setBooking ] = useState(null)
    const [ shareLink, setShareLink ] = useState()
    const [ players, setPlayers ] = useState([])
    const [ publicDetailsGenerated, setPublicDetailsGenerated ] = useState(false)

    if (location.state) {
        const commandProps = location.state
        success = commandProps.success ?? success
        title = commandProps.title ?? title
        subtitle = commandProps.subtitle ?? subtitle
        message = commandProps.message ?? message
        preBookingId = commandProps.preBookingId ?? preBookingId
        bookingId = commandProps.bookingId ?? bookingId
        noInvit = commandProps.noInvit ?? noInvit
        noThanks = commandProps?.noThanks ?? noThanks
        confirmedSubscription = commandProps.confirmedSubscription ?? confirmedSubscription
        secondSubscription = commandProps.secondSubscription ?? secondSubscription
        tunnelType = commandProps.tunnelType ?? tunnelType
        isCanceled = commandProps.isCanceled ?? isCanceled
        customLink = commandProps.customLink ?? customLink
        customContentIdentifier = commandProps.customContentIdentifier ?? customContentIdentifier
        doRefresh = commandProps.doRefresh ?? doRefresh
        fromGestion = commandProps.fromGestion ?? fromGestion
        locationId = commandProps.locationId ?? locationId
        underThanks = commandProps.underThanks ?? underThanks
        krAnswer = commandProps.krAnswer ?? krAnswer
    }
    const locationPlayersNumber = booking ? playersNumber?.find(item => item.types.includes(booking.resourceType))?.players : 10
    const teamDisplay = locationPlayersNumber ? Array.from(Array(locationPlayersNumber / 2)).map((_, i) => `${i + 1}`) : [ "joueur1", "joueur2", "joueur3", "joueur4", "joueur5" ]

    useResetScroll()

    function returnToListing() {
        if (customLink && customContentIdentifier) {
            if (doRefresh) {
                navigate(path(customLink))
                window.location.reload()
            } else {
                navigate(path(customLink))
            }
        } else {
            switch (tunnelType) {
            case "BIRTHDAY":
                navigate(path("/kids/anniversaire/listing"))
                break

            case "SCHOOL":
                navigate(themeFeatures([ "/kids/ecoleDeFoot/listing", "/padel/cours" ]))
                break

            case "CUP":
                navigate(themeFeatures([ "/cup/listing", "/padel/tournois/listing" ]))
                break

            case "TRAINING":
                navigate(themeFeatures([ "/kids/stagesDeFoot/listing", "/padel/stages" ]))
                break

            case "LEAGUE":
                navigate(path("/urbanleague/inscriptionLeague"))
                break

            case "BUSINESS_LEAGUE":
                navigate(path("/"))
                break

            case "BILL":
                navigate(path("/profil/factures"))
                break

            case "DEBT":
                navigate(path("/profil/factures"))
                break

            case "SUPERSUB":
                navigate(path(LocationPaths.toReservationPlayers(confirmedSubscription.id)))
                break

            case "BOOKING":
                if (fromGestion) {
                    if (title === "Réservation annulée") {
                        navigate(path("/reservations/locations"))
                    } else {
                        navigate(path(LocationPaths.toReservationPayments(locationId)))
                    }
                } else if (!success || message === "cancelPayment") {
                    const params = {
                        centerId: searchParams.get("centerId"),
                        date: searchParams.get("date"),
                        discountId: searchParams.get("discountId"),
                        discountLabel: searchParams.get("discountLabel"),
                        duration: searchParams.get("duration"),
                        hour: searchParams.get("hour"),
                        mn: searchParams.get("mn"),
                        resourceType: searchParams.get("resourceType"),
                        typeName: searchParams.get("typeName"),
                    }
                    Object.keys(params).forEach((param) => !params[param] && delete params[param])
                    if (preBookingId) {
                        navigate({
                            pathname: path("/reserver/" + preBookingId),
                            search: createSearchParams(params).toString(),
                        })
                    } else {
                        navigate({
                            pathname: path("/reserver"),
                            search: createSearchParams(params).toString(),
                        })
                    }
                }
                break

            default:
                // eslint-disable-next-line no-console
                console.error("Unhandled case : %o", tunnelType)
                navigate(path("/"))
                break
            }
        }
    }

    function getSubscriptionPictureUrl() {
        switch (tunnelType) {
        case "BIRTHDAY":
            return confirmedSubscription?.birthdayConfig?.type?.pictureUrl ? confirmedSubscription.birthdayConfig.type.pictureUrl :
                mediaPath([ "/assets/images/blasonUS.png", "/assets/images/padel/logo/padel-background-white.svg" ])
        case "SCHOOL":
            return confirmedSubscription?.school?.pictureUrl ? confirmedSubscription.school.pictureUrl :
                mediaPath([ "/assets/images/blasonUS.png", "/assets/images/padel/logo/padel-background-white.svg" ])
        case "CUP" :
            return confirmedSubscription?.cup?.pictureUrl ? confirmedSubscription.cup.pictureUrl :
                mediaPath([ "/assets/images/placeholder-team.svg", "/assets/images/padel/logo/padel-background-white.svg" ])
        case "TRAINING":
            return confirmedSubscription?.training?.pictureUrl ? confirmedSubscription.training.pictureUrl :
                mediaPath([ "/assets/images/placeholder-team.svg", "/assets/images/padel/logo/padel-background-white.svg" ])
        case "LEAGUE":
            return confirmedSubscription?.league?.pictureUrl ? confirmedSubscription.league.pictureUrl :
                mediaPath([ "/assets/images/blasonUS.png", "/assets/images/padel/logo/padel-background-white.svg" ])
        case "BUSINESS_LEAGUE":
            return "/assets/images/business/logo-business-icon.svg"
        case "SUPERSUB":
            return mediaPath([ "/assets/images/blasonUS.png", "/assets/images/padel/logo/padel-background-white.svg" ])
        case "BOOKING":
            return mediaPath([ "/assets/images/blasonUS.png", "/assets/images/padel/logo/padel-background-white.svg" ])
        case "bill":
        case "BILL":
            return mediaPath([ "/assets/images/blasonUS.png", "/assets/images/padel/logo/padel-background-white.svg" ])
        case "debt":
        case "DEBT":
            return mediaPath([ "/assets/images/blasonUS.png", "/assets/images/padel/logo/padel-background-white.svg" ])
        default:
            return mediaPath([ "/assets/images/blasonUS.png", "/assets/images/padel/logo/padel-background-white.svg" ])
        }
    }

    function getPhoneNumber() {
        return "0 806 23 00 24"
    }

    const refreshBooking = () => {
        getLocation(bookingId).then(res => {
            setBooking(res)
        })
    }

    useEffect(
        () => {
            dispatch(loadUrlQueryParameters(null))
            getUser()
            if (message) {
                switch (message) {
                case "supersubCreationConfirm":
                    setCustomMessage(supersubCreationConfirm())
                    break
                case "organizerAcceptSupersub":
                    setCustomMessage(organizerAcceptSupersub())
                    break
                case "organizerRefuseSupersub":
                    setCustomMessage(organizerRefuseSupersub(location.state.supersubPlayer))
                    break
                case "closeSupersubRequest":
                    setCustomMessage(closeSupersubRequest())
                    break
                case "bookingConfirm":
                    setCustomMessage(bookingConfirm(tu))
                    break
                case "bookingConfirmCompo":
                    setCustomMessage(bookingConfirmCompo())
                    break
                case "cancelPayment":
                    setCustomMessage(cancelPayment())
                    break
                case "bookingPaymentConfirm":
                    setCustomMessage(bookingPaymentConfirm())
                    break
                case "bookingSuccess":
                    setCustomMessage(bookingSuccess(location.state.queryParameters, navigate, path, tu))
                    break
                case "playerSupersubConfirm":
                    setCustomMessage(playerSupersubConfirm())
                    break
                case "freeSupersubConfirm":
                    setCustomMessage(freeSupersubConfirm())
                    break
                case "isRedirectToListing":
                    setCustomMessage("Tu peux retourner au listing en cliquant sur le bouton ci dessous :)")
                    break
                case "apiError":
                    setCustomMessage(apiError())
                    break
                case "preregisterConfirm":
                    setCustomMessage(preregisterConfirm(tunnelType, location.state.limitDate, location.state.firstSubscription))
                    break
                case "bookingWithoutPaying":
                    setCustomMessage(bookingWithoutPaying(location.state.confirmedSubscription, navigate, path))
                    break
                case "banSupersub":
                    setCustomMessage(banSupersub())
                    break
                case "acceptBookingInvitation":
                    setCustomMessage(acceptBookingInvitation(location.state.locationDetails, navigate, path, generateShareLink(
                        {
                            ...location.state.locationDetails,
                            publicJoinToken: location.state.token,
                        },
                        theme,
                        publicDetailsGenerated,
                        setPublicDetailsGenerated,
                    ), theme))
                    break
                default:
                    setCustomMessage(message)
                }
            }
            if (subtitle) {
                switch (subtitle) {
                case "trainingConfirm":
                    setCustomSubtitle(trainingConfirm(confirmedSubscription))
                    break
                case "leagueSharePaymentConfirm":
                    setCustomSubtitle(leagueSharePaymentConfirm(confirmedSubscription))
                    break
                case "leagueConfirm":
                    setCustomSubtitle(leagueConfirm(confirmedSubscription))
                    break
                case "cupConfirm":
                    setCustomSubtitle(cupConfirm(confirmedSubscription))
                    break
                default:
                    setCustomSubtitle(subtitle)
                }
            }
            if (success) {getFactures().then(
                res => {
                    const typesToExclude = [ 19, 20, 24, 28, 29, 30, 34, 36, 37, 39, 41, 43, 99 ]
                    const filteredBill = res?.filter(bill => !typesToExclude.includes(bill.serviceType))
                    const notPaid = filteredBill?.filter(bill => bill.status === 0)
                    dispatch(hasUnpaidBills(notPaid?.length > 0))
                },
            )}

            const transaction = krAnswer?.transactions[0]
            const promoCode = transaction?.metadata?.discountCode ? transaction?.metadata?.discountCode.split("|") : []

            if (bookingId || locationId) {
                getLocation(bookingId || locationId).then(res => {
                    setBooking(res)
                    setShareLink(generateShareLink(res, theme, publicDetailsGenerated, setPublicDetailsGenerated))
                    const part = Number(transaction?.metadata?.part) || 1
                    const players = Number(transaction?.metadata?.players) || 1
                    const baseHTPrice = res?.discountedNetPrice
                    const adjustedHTPrice = (baseHTPrice) / players

                    if (res?.id) {
                        trackEcommerceEvent("product.purchased", buildDidomiEcommerceData(res, tunnelType, themeFeatures, {
                            "cart_id": transaction?.metadata?.preReservationId || bookingId || locationId,
                            "product_pricetaxfree": Number(adjustedHTPrice).toFixed(2),
                            "product_pricetaxincluded": Number(transaction?.metadata?.pricePerPart) || 0,
                            "product_quantity": part,
                            "transaction_id": transaction?.uuid,
                            "transaction_promocode": promoCode,
                        }))
                    }

                    setPlayers([ { ...user, teamId:1, teamSlot:0 } ])
                })
            } else {
                trackEcommerceEvent("product.purchased", buildDidomiEcommerceData(confirmedSubscription, tunnelType, themeFeatures, {
                    "transaction_id": transaction?.uuid,
                    "transaction_promocode": promoCode,
                }))

                if (secondSubscription) {
                    getKidsSchoolSubscription(secondSubscription).then((schoolSubscription) => {
                        trackEcommerceEvent("product.purchased", buildDidomiEcommerceData(schoolSubscription, tunnelType, themeFeatures, {
                            "transaction_id": transaction?.uuid,
                            "transaction_promocode": promoCode,
                        }))
                    })
                }
            }

            return () => {
                clearStore()
                setCurrentLocation({})
                return dispatch(clearAllForms())
            }
        },[],
    )

    const { show } = ModalHandler.useReactiveModal(BookingInvitation, {
        addCallback: refreshBooking,
        location: booking,
    })

    useEffect(() => {
        apiLog("PAYMENT CONFIRMATION", { customMessage, message, success })
    }, [])

    return (
        <React.Fragment>
            { success ? (
                <div className="confirmationCmd">
                    <div className="confirmationCmd__header">
                        <div className="confirmationCmd__header__inner">
                            <div className="title" dangerouslySetInnerHTML={{ __html:title }}></div>
                            <div className="subTitle">{ customSubtitle }</div>
                            <div className={ getSubscriptionPictureUrl() === "/assets/images/padel/logo/padel-background-white.svg" ? "logo bigPadel" : "logo"}>
                                <img src={getSubscriptionPictureUrl()} />
                            </div>
                        </div>
                    </div>
                    <div className="confirmationCmd__content">
                        <div className="confirmationCmd__content__inner">
                            <div className="main-p">
                                { customMessage }
                                { !noThanks && !isCanceled && (
                                    <React.Fragment>
                                        { tunnelType !== "SUPERSUB" && tunnelType !== "BOOKING" && (
                                            (tunnelType === "LEAGUE" || tunnelType === "CUP" || tunnelType === "DEBT" || tunnelType === "BILL") ?
                                                <strong><br></br>{tu("familiarityTrustThanks")}</strong>
                                                :
                                                <strong><br></br> {tu("formalityTrustThanks")}</strong>
                                        )}
                                    </React.Fragment>
                                )}
                            </div>
                            <section
                                className="buttonsWrapper"
                            >
                                {(preBookingId || bookingId) && (
                                    <>
                                        {!noInvit && (
                                            <ButtonCta

                                                isCta2
                                                text="Ajouter mes joueurs"
                                                onClick={show}
                                            />
                                        )}
                                        <ShareLink label="partager le lien du match" shareLink={shareLink} icon="" />
                                    </>
                                )}
                                {(
                                    (tunnelType !== "BOOKING" && title !== "Réservation annulée")
                                    || (tunnelType === "BOOKING" && (message === "cancelPayment" || fromGestion || message === "Ta réservation a bien été annulée."))
                                    || message === "isRedirectToListing"
                                ) && (
                                    <ButtonCta
                                        text= "Terminer"
                                        onClick= {returnToListing}
                                    />
                                )}
                            </section>
                            {booking && [ 0, 1, 8, 10, 13, 12, 14, 15 ].includes(booking.resourceType) && (
                                <CompositionField
                                    teamDisplay={teamDisplay}
                                    players={players}
                                    location={booking}
                                    team1Name={booking.team1Name}
                                    team2Name={booking.team2Name}
                                />
                            )}
                            { underThanks && <strong><br></br>{tu("familiarityTrustThanks")}</strong> }
                        </div>
                    </div>
                </div>
            ) : (
                <div className="confirmationCmd">
                    <div className="confirmationCmd__header noPadding refused">
                        <div className="confirmationCmd__header__inner">
                            <img className="warningIcon" src={mediaPath([ "/assets/icons/icon--warning.svg" , "/assets/images/padel/icons/icon--warning-red.svg" ])} />
                            <div className="title">Paiement refusé</div>
                            <div className={getSubscriptionPictureUrl() === "/assets/images/padel/logo/padel-background-white.svg" ? "logo bigPadel" : "logo"}>
                                <img src={getSubscriptionPictureUrl()} />
                            </div>
                        </div>
                    </div>
                    <div className="confirmationCmd__content">
                        <div className="confirmationCmd__content__inner">
                            <p className="main-p">
                                Nous sommes désolés mais ton paiement a été réfusé.
                                <br />
                                <span>
                                    Nous t'invitons à renouveler ta commande ou à nous contacter au
                                </span>
                                <span style={{ fontWeight: "bold" }}>
                                    {getPhoneNumber()}.
                                </span>
                            </p>
                            <ButtonCta
                                className="center"
                                text="Terminer"
                                onClick={returnToListing}
                            />
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}

ConfirmationCmd.propTypes = {
    bookingId: oneOfType([ string, number ]),
    confirmedSubscription: object,
    customContentIdentifier: string,
    customLink: string,
    doRefresh: bool,
    fromGestion: bool,
    isCanceled: bool,
    krAnswer: object,
    locationId: string,
    message: oneOfType([ string, node ]),
    noInvit: bool,
    noThanks: bool,
    preBookingId: oneOfType([ string, number ]),
    secondSubscription: object,
    subtitle: oneOfType([ string, node ]),
    success: bool,
    title: string,
    tunnelType: string,
    underThanks: bool,
}

export default ConfirmationCmd
