import KRGlue from "@lyracom/embedded-form-glue"
import classNames from "classnames"
import { bool, string } from "prop-types"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { matchPath, useLocation, useNavigate, useParams } from "react-router-dom"

import Preloader from "../../../../components/loaders/preloader/preloader.jsx"
import MessagePopin from "../../../../components/popin/MessagePopin/MessagePopin.jsx"
import { useDidomiEcommerceEvent } from "../../../../hooks/useDidomiDataLayer.js"
import useMediaPath from "../../../../hooks/useMediaPath.js"
import ModalHandler from "../../../../providers/Modal/ModalHandler.jsx"
import { LocationPaths } from "../../../../routes/ApplicationPaths/locationPaths.js"
import usePath from "../../../../routes/services/usePath.js"
import { apiLog } from "../../../../services/errorHandler.js"
import { extendPreBookValidity } from "../../../reservation/tunnelReservation/api.js"

import "./KrForm.scss"

export default function KrForm({ formToken, endpoint, publicKey, returnUrl, withoutPayment, onlyAlma  }) {
    const [ message, setMessage ] = useState("")
    const userLoaded = useSelector(state => state.userLoaded)
    const [ loading, setLoading ] = useState(true)
    const [ method, setMethod ] = useState("card")
    const [ paymentMethods, setPaymentMethods ] = useState([])
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const path = usePath()
    const mediaPath = useMediaPath()
    const { trackEcommerceEvent } = useDidomiEcommerceEvent()

    const initUserInfo = () => {
        let name = document.querySelector("#myPaymentForm .kr-card-holder-name input.kr-input-field")

        if (name && userLoaded.firstname && userLoaded.lastname) {
            name.value = userLoaded.firstname?.toUpperCase() + " " + userLoaded.lastname?.toUpperCase()
            name.dispatchEvent(new Event("input"))
        }
    }

    const setup = async ()  => {
        try {
            if (KRGlue.loaded && KRGlue.publicKey !== publicKey) {
                window.location.reload()
                return
            }

            const { KR } = await KRGlue.loadLibrary(endpoint, publicKey)

            await KR.setFormConfig({
                formToken: formToken,
                "kr-language": "fr-FR",
                "kr-placeholder-expiry": "MM/AA",
                "kr-placeholder-pan": "Numéro de la carte",
                "kr-placeholder-security-code": "CVV",
                "kr-spa-mode": true,
                smartForm: {
                    paymentMethods: {
                        "ALMA_3X": {
                            label: "En 3X avec Alma",
                        },
                        "APPLE_PAY": {
                            label: "Payer avec Apple Pay",
                        },
                    },
                },
            })

            const m = await KR.getPaymentMethods()

            setPaymentMethods(onlyAlma ? [ "ALMA_3X" ] : m?.paymentMethods)

            if (onlyAlma) {
                setMethod("alma")
            }

            await KR.onError((e) => {
                if (e.errorCode === "INT_912") {
                    window.location.reload()
                } else if (e.errorCode === "PSP_562") {
                    setMessage("Paiement refusé")
                } else if (![ "CLIENT_725", "CLIENT_723" ].includes(e.errorCode)) {
                    setMessage(e.errorMessage)

                    // eslint-disable-next-line no-console
                    console.log(e)
                }
            })

            await KR.smartForm.onClick(() => {
                const isBookingPreBook = matchPath(path(LocationPaths.lyraEmbedPayment),location.pathname)

                // handling booking tunnel preBooking extension
                if (isBookingPreBook && params?.preBookingId) {
                    extendPreBookValidity(params?.preBookingId).then((r) => {
                        if (r?.preReservation?.id) {
                            return true
                        } else {
                            ModalHandler.show(MessagePopin, {
                                message: "La pré-réservation a expiré.",
                                title: "Erreur",
                            })
                            navigate(path(LocationPaths.bookingPage), { replace: true })
                            return false
                        }
                    })
                }
            })

            await KR.onTransactionCreated(r => {
                try {
                    apiLog("PAYMENT RESPONSE", { response: r?.clientAnswer })
                } catch {
                    // NOTHING
                }

                return true
            })

            await KR.on3dSecureAbort(() => {
                try {
                    apiLog("PAYMENT 3D ABORTED", {})

                    setMessage("Paiement annulé")
                } catch {
                    // NOTHING
                }
            })

            await KR.onSubmit((r) => {
                try {
                    if (r?.clientAnswer) {
                        if (r?.clientAnswer?.orderStatus === "PAID" || r?.clientAnswer?.orderStatus === "RUNNING") {
                            const data = r?.clientAnswer
                            const transaction = data?.transactions[0]
                            const orderTotal = parseFloat((data?.orderDetails?.orderEffectiveAmount / 100).toFixed(1))
                            const orderTotalTaxFree = parseFloat((orderTotal / (1 + 0.2)).toFixed(1))
                            const promoCode = transaction?.metadata?.discountCode ? transaction?.metadata?.discountCode.split("|") : []

                            trackEcommerceEvent("transaction.confirmation", {
                                "cart_currency": transaction?.currency,
                                "cart_id": transaction?.metadata?.preReservationId || transaction?.metadata?.resourceId,
                                "cart_nbdistinctproducts": 1,
                                "cart_quantity": Number(transaction?.metadata?.part) || 1,
                                "cart_turnovertaxfree": orderTotalTaxFree,
                                "cart_turnovertaxincluded": orderTotal,
                                "payment_mode": transaction?.paymentMethodType,
                                "shipping_costtaxfree": 0,
                                "shipping_costtaxincluded": 0,
                                "shipping_delivery": "site web",
                                "transaction_id": transaction?.uuid,
                                "transaction_promocode": promoCode,
                                "transaction_status": "charged",
                            })

                            const redirectTo = returnUrl.split("?")[1]
                            navigate("/?" + redirectTo, { replace: true, state: {
                                isPaymentReturn: true,
                                krAnswer: r?.clientAnswer,
                            } })
                        } else if (r?.clientAnswer?.transactions[0]?.detailedStatus !== "CANCELLED") {
                            ModalHandler.show(MessagePopin, {
                                message: "Une erreur est survenue lors du paiement ou vous avez fait trop de tentatives.",
                                title: "Erreur",
                            }).then(() => { navigate(-1) })
                        }
                    }
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e)
                    
                    try {
                        apiLog("ON SUBMIT ERROR", e)

                        ModalHandler.show(MessagePopin, {
                            message: "Une erreur est survenue lors du retour du paiement. Veuillez contacter votre " +
                                "centre pour vérifier que votre paiement a bien été pris en compte.",
                            title: "Erreur",
                        }).then(() => { navigate(-1) })
                    } catch {
                        // NOTHING
                    }
                }

                return true
            })

            await KR.renderElements("#myPaymentForm")

            if (withoutPayment) {
                await KR.button.setLabel("Finaliser")
            } else {
                await KR.button.setLabel("Payer %amount-and-currency%")
            }

            initUserInfo()

            return KR
        } catch (error) {
            setMessage(error + " (see console for more details)")
        }

        return null
    }

    useEffect(() => {
        if (formToken) {
            setup().finally(() => setLoading(false))
        }
    }, [])

    const handleMethodChange = (e) => {
        setMethod(e.target.value)
    }

    return !!formToken && (
        <>
            {loading && <Preloader fixed/>}
            <div className={`payment-form ${loading ? "hidden" : ""} ${paymentMethods.join(" ").toLowerCase()}`}>
                <div className={classNames("selected-method", method)}></div>
                <div id={"myPaymentForm"}>
                    {/* eslint-disable-next-line react/no-unknown-property */}
                    <div className="kr-smart-form" kr-card-form-expanded="true">
                        <div className="kr-embedded">
                            <div className={`payment-method cards ${!onlyAlma ? "visible" : ""}`}>
                                <div className={"payment-method-head"}>
                                    <div className={"c-inputHolder--radioRound"}>
                                        <input
                                            type={"radio"}
                                            id={"payment-cards"}
                                            checked={method === "card"}
                                            name={"payment-method"}
                                            value={"card"}
                                            onChange={handleMethodChange}
                                        />
                                        <label htmlFor={"payment-cards"}>Carte bancaire</label>
                                    </div>

                                    <div className={"payment-method-icon"}>
                                        <img
                                            src={mediaPath([
                                                "/assets/icons/card-orange.svg",
                                                "/assets/icons/card-green.svg",
                                            ])}
                                        />
                                    </div>
                                </div>

                                <div className={"payment-method-content"}>
                                    <div className={"payment-icon-cards"}>
                                        <div className={"payment-icon-cards-title"}>
                                            Cartes
                                        </div>

                                        <div>
                                            <img src="/assets/icons/cb.png"/>
                                        </div>

                                        <div>
                                            <img src="/assets/icons/mastercard1.png"/>
                                        </div>

                                        <div>
                                            <img src="/assets/icons/mastercard2.png"/>
                                        </div>

                                        <div>
                                            <img src="/assets/icons/visa.png"/>
                                        </div>
                                    </div>
                                    <div className={"payment-method-fields col-2"}>
                                        <div className="kr-card-holder-name"></div>
                                    </div>
                                    <div className={"payment-method-fields"}>
                                        <div className="kr-pan"/>
                                        <div className="kr-expiry"/>
                                        <div className="kr-security-code"/>
                                        <div className="kr-do-register"/>
                                    </div>
                                    <button className="kr-payment-button buttonCta"></button>
                                    {withoutPayment && (
                                        <div className={"empreinte"}>
                                            <p>Empreinte carte bancaire</p>
                                        </div>
                                    )}

                                    <div className={"secure-label"}>
                                        <img src="/assets/icons/lock-gray.svg" alt="lock" className="lock"/>
                                        <span className="secure">Paiement 100% sécurisé</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"payment-method apple_pay"}>
                        <div className={"payment-method-head"}>
                            <div className={"c-inputHolder--radioRound"}>
                                <input
                                    type={"radio"}
                                    id={"payment-apple-pay"}
                                    checked={method === "apple_pay"}
                                    onChange={handleMethodChange}
                                    name={"payment-method"}
                                    value={"apple_pay"}
                                />
                                <label htmlFor={"payment-apple-pay"}>Apple Pay</label>
                            </div>

                            <div className={"payment-method-icon"}>
                                <img
                                    src={mediaPath([ "/assets/icons/alma-orange.svg", "/assets/icons/alma-green.svg" ])}/>
                            </div>
                        </div>

                        <div className="payment-method-content">
                            {/* eslint-disable-next-line react/no-unknown-property */}
                            <div className="kr-smart-button" kr-payment-method="APPLE_PAY" />

                            <div className={"secure-label"}>
                                <img src="/assets/icons/lock-gray.svg" alt="lock" className="lock"/>
                                <span className="secure">Paiement 100% sécurisé</span>
                            </div>
                        </div>
                    </div>

                    <div className={"payment-method alma_3x"}>
                        <div className={"payment-method-head"}>
                            <div className={"c-inputHolder--radioRound"}>
                                <input
                                    type={"radio"}
                                    id={"payment-alma"}
                                    name={"payment-method"}
                                    value={"alma"}
                                    checked={method === "alma"}
                                    onChange={handleMethodChange}
                                />
                                <label htmlFor={"payment-alma"}>Paiement avec ALMA 3x sans frais</label>
                            </div>

                            <div className={"payment-method-icon"}>
                                <img
                                    src={mediaPath([ "/assets/icons/alma-orange.svg", "/assets/icons/alma-green.svg" ])}/>
                            </div>
                        </div>

                        <div className="payment-method-content">
                            {/* eslint-disable-next-line react/no-unknown-property */}
                            <div className="kr-smart-button" kr-payment-method="ALMA_3X" />

                            <div className={"secure-label"}>
                                <img src="/assets/icons/lock-gray.svg" alt="lock" className="lock"/>
                                <span className="secure">Paiement 100% sécurisé</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="payment-message">{message}</div>
            </div>
        </>
    )
}

KrForm.propTypes = {
    endpoint: string,
    formToken: string,
    onlyAlma: bool,
    publicKey: string,
    returnUrl: string,
    withoutPayment: bool,
}
