import sha256 from "crypto-js/sha256.js"
import dayjs from "dayjs"

export const getDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase()
    const isTablet =
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
            userAgent,
        )

    const isPhone = /android|webos|iphone|ipod|blackberry|iemobile/i.test(userAgent) && !/ipad/i.test(userAgent)

    if (isTablet) {
        return "tablet"
    }
    if (isPhone) {
        return "mobile"
    }
    return "desktop"
}

export const getLang = () => {
    const lang = document.documentElement.lang
    return lang.replace("-", "_") || ""
}

export const getChapters = (pathname) => {
    if (pathname === "/") {
        return [ "homepage", "home-site" ]
    }

    return pathname.split("/").filter(Boolean)
}

export const getUserData = (user = null) => {
    const hashedEmail = user?.emailAddress ? sha256(user.emailAddress).toString() : ""
    return {
        user_id: hashedEmail || "undefined",
        user_status: hashedEmail ? "logged" : "nonlogged",
    }
}

export const getBookingFunnelType = (location, themeFeatures) => {
    const { pathname, state } = location
    
    if (pathname.startsWith("/order/payment/")) {
        const segments = pathname.split("/")
        return segments[3] || ""
    }

    if (pathname.startsWith("/urbanleague/new")) {
        return "soccer_league"
    }
    if (pathname.startsWith("/cup/new")) {
        return "soccer_cup"
    }
    if (pathname.startsWith("/kids/anniversaire/new")) {
        return "soccer_birthday"
    }
    if (pathname.startsWith("/kids/stagesDeFoot/new")) {
        return "soccer_training"
    }
    if (pathname.startsWith("/kids/ecoleDeFoot/new")) {
        return "soccer_school"
    }
    if (pathname.startsWith("/reserver")) {
        return "soccer_booking"
    }
    if (pathname.startsWith("/padel/tournois/new")) {
        return "padel_cup"
    }
    if (pathname.startsWith("/padel/coursStages/inscription")) {
        return "padel_cours_stage"
    }
    if (pathname.startsWith("/padel/kids/anniversaire/new")) {
        return "padel_birthday"
    }
    if (pathname.startsWith("/padel/reserver")) {
        return "padel_booking"
    }

    if (state?.tunnelType) {
        return themeFeatures([ "soccer", "padel" ]) + "_" + state.tunnelType?.toLowerCase()
    }

    return undefined
}

export const buildDidomiEcommerceData = (data, tunnelType, themeFeatures, override) => {
    let tunnel = tunnelType?.toUpperCase()

    switch (tunnel) {
    case "BOOKING":
        return {
            "cart_id": data?.id,
            "product": data?.resourceTypeDisplay,
            "product_brand": "Urban " + themeFeatures([ "Soccer", "Padel" ]),
            "product_category1": data?.typeDisplay,
            "product_category2": data?.centerName,
            "product_category3": data?.resourceTypeDisplay,
            // date format "sam. 07/08 - 10h00"
            "product_category4": data?.start ? dayjs(data?.start).format("ddd DD/MM - HH[h]mm") : "",
            "product_id": data?.typeDisplay,
            "product_pricetaxfree": data?.discountedNetPrice,
            "product_pricetaxincluded": data?.discountedPrice,
            "product_quantity": 1,
            "product_variant": data?.duration,
            ...override,
        }
    case "SCHOOL":
        return {
            "cart_id": data?.id,
            "product": data?.school?.name,
            "product_brand": "Urban " + themeFeatures([ "Soccer", "Padel" ]),
            "product_category1": "Ecole de " + themeFeatures([ "Foot", "Padel" ]),
            "product_category2": data?.school?.centerName,
            "product_category3": data?.school?.name,
            // // date format "sam. 07/08 - 10h00"
            "product_category4": data?.school?.start ? dayjs(data?.school?.start).format("ddd DD/MM - HH[h]mm") : "",
            "product_id": data?.school?.type,
            "product_pricetaxfree": data?.bill?.amountHT || data?.proforma?.amountHT,
            "product_pricetaxincluded": data?.bill?.amountTTC || data?.proforma?.amountTTC,
            "product_quantity": 1,
            "product_variant":  dayjs(data?.school?.end).diff(dayjs(data?.school?.start), "days") + " jours",
            ...override,
        }
    case "TRAINING":
        return {
            "cart_id": data?.id,
            "product": data?.training?.name,
            "product_brand": "Urban " + themeFeatures([ "Soccer", "Padel" ]),
            "product_category1": "Stage de " + themeFeatures([ "Foot", "Padel" ]),
            "product_category2": data?.training?.centerName,
            "product_category3": data?.training?.name,
            // // date format "sam. 07/08 - 10h00"
            "product_category4": data?.training?.start ? dayjs(data?.training?.start).format("ddd DD/MM - HH[h]mm") : "",
            "product_id": data?.training?.type,
            "product_pricetaxfree": data?.bill?.amountHT || data?.proforma?.amountHT,
            "product_pricetaxincluded": data?.bill?.amountTTC || data?.proforma?.amountTTC,
            "product_quantity": 1,
            "product_variant": dayjs(data?.training?.end).diff(dayjs(data?.training?.start), "days") + " jours",
            ...override,
        }
    case "BIRTHDAY":
        return {
            "cart_id": data?.id,
            "product": data?.birthdayConfig?.name,
            "product_brand": "Urban " + themeFeatures([ "Soccer", "Padel" ]),
            "product_category1": "Anniversaire " + themeFeatures([ "Soccer", "Padel" ]),
            "product_category2": data?.birthdayConfig?.centerName,
            "product_category3": data?.birthdayConfig?.name,
            // // date format "sam. 07/08 - 10h00"
            "product_category4": data?.start ? dayjs(data?.start).format("ddd DD/MM - HH[h]mm") : "",
            "product_id": data?.birthdayConfig?.id,
            "product_pricetaxfree": data?.bill?.amountHT || data?.proforma?.amountHT,
            "product_pricetaxincluded": data?.bill?.amountTTC || data?.proforma?.amountTTC,
            "product_quantity": 1,
            "product_variant": Math.floor(data.duration / 60) + " heures",
            ...override,
        }
    case "CUP":
        return {
            "cart_id": data?.id,
            "product": data?.cup?.name,
            "product_brand": "Urban " + themeFeatures([ "Soccer", "Padel" ]),
            "product_category1": `Cup de ${themeFeatures([ "Soccer", "Padel" ])}`,
            "product_category2": data?.cup?.centerName,
            "product_category3": data?.cup?.name,
            // date format "sam. 07/08 - 10h00"
            "product_category4": data?.cup?.start ? dayjs(data?.cup?.start).format("ddd DD/MM - HH[h]mm") : "",
            "product_id": data?.cup?.type,
            "product_pricetaxfree": data?.bill?.amountHT || data?.proforma?.amountHT,
            "product_pricetaxincluded": data?.bill?.amountTTC || data?.proforma?.amountTTC,
            "product_quantity": 1,
            "product_variant": "",
            ...override,
        }
    case "LEAGUE":
        return {
            "cart_id": data?.id,
            "product": data?.league?.name,
            "product_brand": "Urban Soccer",
            "product_category1": "League Soccer",
            "product_category2": data?.league?.centerName,
            "product_category3": data?.league?.name,
            "product_id": data?.league?.type,
            "product_pricetaxfree": data?.bill?.amountHT || data?.proforma?.amountHT,
            "product_pricetaxincluded": data?.bill?.amountTTC || data?.proforma?.amountTTC,
            "product_quantity": 1,
            "product_variant": "",
            ...override,
        }
    case "SUPERSUB":
        return {
            "cart_id": data?.id,
            "product": data?.resourceTypeDisplay,
            "product_brand": "Urban " + themeFeatures([ "Soccer", "Padel" ]),
            "product_category1": data?.typeDisplay,
            "product_category2": data?.centerName,
            "product_category3": data?.resourceTypeDisplay,
            // date format "sam. 07/08 - 10h00"
            "product_category4": data?.start ? dayjs(data?.start).format("ddd DD/MM - HH[h]mm") : "",
            "product_id": data?.typeDisplay,
            "product_pricetaxfree": data?.discountedNetPrice,
            "product_pricetaxincluded": data?.discountedPrice,
            "product_quantity": 1,
            "product_variant": data?.duration,
            ...override,
        }
    }
}
