import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import { bool, func, number, object, oneOfType , string } from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"

import CurrencyDisplay from "../../../../components/currencies/CurrencyDisplay"
import CheckBlock from "../../../../components/Forms/CheckBlock/CheckBlock"
import FormValidator from "../../../../components/Forms/FormValidator"
import Preloader from "../../../../components/loaders/preloader/preloader"
import PhoneCallHelp from "../../../../components/popin/help/phoneCall"
import MessagePopin from "../../../../components/popin/MessagePopin/MessagePopin.jsx"
import ReservationSlotInfoCard from "../../../../components/reservation/slotInfoCard/ReservationSlotInfoCard"
import Step from "../../../../components/stepsManagement/Step"
import HourDisplay from "../../../../components/Time/HourDisplay"
import { getUser } from "../../../../globalAPI/api"
import days from "../../../../model/enums/dayValues"
import genderValues from "../../../../model/enums/genderValues"
import ModalHandler from "../../../../providers/Modal/ModalHandler.jsx"
import dateToYear from "../../../../services/dateToYear"
import TimeSort from "../../../../services/Sorting/TimeSort"
import { checkSkipStepEDF } from "../../../../store/actions/scaffold"
import { clearSchoolOptions, setSchoolSubscription } from "../../../../store/cart/actions"
import { clearAllForms, removeFormEntry, setFormEntry } from "../../../../store/forms/actions"
import { goToStep } from "../../../../store/stepsManagement/actions"
import { editStageSubscription, getAllKidsStageSubscriptions, getKidsStageDetail } from "../../stageDeFoot/api"
import { getKidsStages } from "../../stageDeFoot/api"
import ChooseCenterAndType from "../../steps/ChooseCenterAndType"
import MesEnfants from "../../steps/MesEnfants"
import NikeEquipment from "../../steps/NikeEquipment"
import { editSchoolSubscription, getAllKidsSchoolsSubscriptions, getKidsSchools } from "../api"

dayjs.extend(customParseFormat)
/**
 * Étape du formulaire EcoleDeFoot pour choisir le créneau voulu en fonction des infos saisies précédement.
 */
class CreneauxDispo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            actualSubscriptions: undefined,
            ajaxLoading: false,
            canLoadTimeslots: false,
            isMobile: false,
            lastChooseCenterAndType: undefined,
            lastMesEnfants: undefined,
            loaded: false,
            selectedStageDetails: undefined,
        }

        this.getAvailableSlots = this.getAvailableSlots.bind(this)
        this.onTimeslotClick = this.onTimeslotClick.bind(this)
        this.ozmoRegistration = this.ozmoRegistration.bind(this)
        this.subscribeToWaitingList = this.subscribeToWaitingList.bind(this)

        this.step = React.createRef()
        this.form = React.createRef()
    }

    /**
     * Lorsque le composant est chargé, vérifie si l'étape est validé
     */
    componentDidMount() {
        document.querySelector("#content").scrollTo(0, 0)
        document.querySelector("html").scrollTo(0, 0)
        window.scrollTo(0, 0)
        const { forms, checkSkipStepEDFAction } = this.props
        const schoolSub = forms[CreneauxDispo.formName] !== undefined ? forms[CreneauxDispo.formName].selectedTimeslot : {}
        const firstSubscription = forms.ozmoSubscription ?
            forms.ozmoSubscription.firstSubscription
            :
            {}

        let schoolDetail
        if (schoolSub) {
            schoolDetail = schoolSub.schoolDetails
        } else {
            schoolDetail = firstSubscription.school
        }
        if (schoolDetail) {
            if (schoolDetail.full) {
                this.step?.current?.unvalidate()
            }
            this.step.current.setRecap(
                <div>
                    {schoolSub ?
                        days[schoolSub.day]
                        :
                        days[firstSubscription.school.day]}
                    <br />
                    {<HourDisplay minutes={schoolDetail.hour} />}
                </div>,
            )
        }

        let mesEnfants = forms[MesEnfants.formName]
        if (mesEnfants !== undefined) {
            if (mesEnfants.selectedKid !== undefined) {
                // eslint-disable-next-line react/no-direct-mutation-state
                this.state.lastMesEnfants = mesEnfants
            }
        }

        if (forms[CreneauxDispo.formName]) {
            if (forms[CreneauxDispo.formName].selectedTimeslot) {
                this.setState({
                    checkSkipStepEDF: checkSkipStepEDFAction(forms, forms[CreneauxDispo.formName].selectedTimeslot).payload,
                })
            }
        }

        this.handleMobileView()
    }

    componentDidUpdate(prevProps) {
        const { forms, setFormInStore } = this.props
        const { tunnelType } = this.props
        const formCreneau = forms[CreneauxDispo.formName]
        const formDataCreneau = forms[CreneauxDispo.formDataName]

        // Check if selectedTimeslotId is valid
        if (
            formCreneau?.selectedTimeslotId !== undefined &&
            formDataCreneau?.timeslots &&
            formDataCreneau.timeslots[formCreneau.selectedTimeslotId]
        ) {
            const selectedTimeslot = formDataCreneau.timeslots[formCreneau.selectedTimeslotId]

            // Update formCreneau and set state
            setFormInStore(CreneauxDispo.formName, {
                ...formCreneau,
                selectedTimeslot,
                selectedTimeslotId: undefined,
                selectedTimeslotValue: selectedTimeslot.value,
            })

            // Validate or unvalidate based on tunnelType
            if (
                tunnelType === "TRAINING" &&
                ((selectedTimeslot?.id && !selectedTimeslot.full) ||
                    (selectedTimeslot?.value && !selectedTimeslot.schoolDetails?.full))
            ) {
                this.step?.current?.validate()
            } else if (tunnelType === "SCHOOL") {
                const selectedTimeslotDetails = forms?.choixCreneau?.selectedTimeslot?.schoolDetails
                if (selectedTimeslotDetails?.canRegister && !selectedTimeslotDetails?.full) {
                    this.step?.current?.validate()
                } else {
                    this.step?.current?.unvalidate()
                }
            }
        }

        // Ensure form updates are applied before calling getAvailableSlots
        if (prevProps.forms !== forms) {
            this.getAvailableSlots()
        }
    }

    /**
     * Récupère les informations des steps précédentes depuis le store, les vérifient et récupère les créneaux disponibles correspondants
     */
    getAvailableSlots() {
        const { forms, setFormInStore, tunnelType } = this.props
        const { actualSubscriptions } = this.state

        let choixEcole = forms[ChooseCenterAndType.formName]
        let mesEnfants = forms[MesEnfants.formName]
        const formCreneau = forms[CreneauxDispo.formName]
        const formDataCreneau = forms[CreneauxDispo.formDataName] ? forms[CreneauxDispo.formDataName] : {}

        const { lastChooseCenterAndType, lastMesEnfants } = this.state

        let selectedKidId
        let selectedKidBirthYear
        if (mesEnfants !== undefined) {
            if (mesEnfants.selectedKid !== undefined) {
                selectedKidId = mesEnfants.selectedKid.id
                selectedKidBirthYear = mesEnfants.kidBirthYear
            }
        }

        // Si le type d'école, le centre ou l'enfant ont changés, on met à jour les anciennes données dans le store
        if (
            formDataCreneau !== undefined &&
            selectedKidId !== undefined &&
            choixEcole !== undefined
        ) {
            if (
                formDataCreneau.lastTypeId !== choixEcole.typeId ||
                formDataCreneau.lastCenterId !== choixEcole.centerId ||
                formDataCreneau.lastSelectedKidId !== selectedKidId ||
                formDataCreneau.lastSelectedKidBirthYear !== selectedKidBirthYear
            ) {
                let actualKidSubscriptionsIds
                let getSubIdFromRegistration
                switch (tunnelType) {
                case "SCHOOL":
                    getSubIdFromRegistration = sub => sub.school.id
                    break
                case "TRAINING":
                    getSubIdFromRegistration = sub => sub.training.id
                    break
                }
                if (actualSubscriptions !== undefined) {
                    actualKidSubscriptionsIds = []
                    actualSubscriptions.forEach(
                        subscription => {
                            if (subscription.kid !== undefined) {
                                if (subscription.kid.id === selectedKidId) {
                                    actualKidSubscriptionsIds.push(
                                        getSubIdFromRegistration(subscription),
                                    )
                                }
                            }
                        },
                    )
                }

                setFormInStore(CreneauxDispo.formDataName, {
                    ...formDataCreneau,
                    actualKidSubscriptionsIds: actualKidSubscriptionsIds,
                    lastCenterId: choixEcole.centerId,
                    lastSelectedKidBirthYear: selectedKidBirthYear,
                    lastSelectedKidId: selectedKidId,
                    lastTypeId: choixEcole.typeId,
                })
            }
        }

        const getCreneaux = (actualKidSubscriptionsIds) => {
            //Si le centre, le type d'école ou l'âge de l'enfant selectionné à changé, on met à jour les écoles
            if (
                choixEcole !== undefined &&
                mesEnfants !== undefined &&
                mesEnfants.kidBirthYear !== undefined &&
                lastChooseCenterAndType !== undefined
            ) {
                if (
                    lastChooseCenterAndType.centerId !== choixEcole.centerId ||
                    lastChooseCenterAndType.typeId !== choixEcole.typeId ||
                    lastMesEnfants === undefined ||
                    lastMesEnfants.kidBirthYear !== mesEnfants.kidBirthYear
                ) {
                    setFormInStore(CreneauxDispo.formDataName, {
                        ...formDataCreneau,
                        timeslots: undefined,
                    })

                    let data

                    // eslint-disable-next-line react/no-direct-mutation-state
                    this.state.canLoadTimeslots = false
                    const { tunnelType } = this.props

                    switch (tunnelType) {
                    case "SCHOOL":
                        data = {
                            birthYear: dateToYear(mesEnfants.kidBirthYear),
                            "centersId[0]": choixEcole.centerId,
                            schoolType: choixEcole.typeId,
                        }
                        this.setState({
                            ajaxLoading: true,
                        })
                        getKidsSchools(data).then(
                            (res) => {
                                if (res) {
                                    const timeslots = {}
                                    res.sessions.forEach(timeslot => {
                                        timeslots[timeslot.value] = timeslot
                                    })
                                    if (actualKidSubscriptionsIds) {
                                        setFormInStore(CreneauxDispo.formDataName, {
                                            ...formDataCreneau,
                                            actualKidSubscriptionsIds: actualKidSubscriptionsIds,
                                            timeslots: timeslots,
                                        })
                                    } else {
                                        setFormInStore(CreneauxDispo.formDataName, {
                                            ...formDataCreneau,
                                            timeslots: timeslots,
                                        })
                                    }
                                }
                                this.setState({
                                    ajaxLoading: false,
                                })
                            },
                        )
                        break
                    case "TRAINING":
                        data = {
                            centerId: choixEcole.centerId,
                            stageType: choixEcole.typeId,
                        }
                        this.setState({
                            ajaxLoading: true,
                        })
                        getKidsStages(data).then(
                            (res) => {
                                const timeslots = {}
                                const selectedKid = forms[MesEnfants.formName].selectedKid
                                let selectedKidDate = selectedKid.birthDate

                                if (selectedKidDate.includes("/")) {
                                    selectedKidDate = selectedKidDate.split("/");
                                    [ selectedKidDate[0], selectedKidDate[1] ] = [ selectedKidDate[1], selectedKidDate[0] ]
                                } else {
                                    selectedKidDate = selectedKidDate.split("-");
                                    [ selectedKidDate[0], selectedKidDate[1], selectedKidDate[2] ] = [ selectedKidDate[1], selectedKidDate[2], selectedKidDate[0] ]
                                }

                                selectedKidDate = selectedKidDate.join("/")

                                let selectedKidAgeAtSlotStart = startDate => dayjs(startDate).diff(dayjs(selectedKidDate), "year")
                                
                                if (res.data.data && res.data.data.sessions) {
                                    res.data.data.sessions.forEach(timeslot => {
                                        const startDate = dayjs(timeslot.start)
                                        const selectedKidAge = selectedKidAgeAtSlotStart(startDate)
                                        const { minAge, maxAge } = timeslot
                                        if (
                                            !(
                                                (minAge && selectedKidAge < minAge)
                                                ||
                                                (maxAge && selectedKidAge > maxAge)
                                            )
                                        ) {
                                            timeslots[timeslot.id] = timeslot
                                        }
                                    })
                                } else {
                                    this.setState({
                                        ajaxLoading: true,
                                    })
                                    // eslint-disable-next-line no-console
                                    console.error("BAD getKidsStages RESULT : %o", res)
                                }
                                if (actualKidSubscriptionsIds) {
                                    setFormInStore(CreneauxDispo.formDataName, {
                                        ...formDataCreneau,
                                        actualKidSubscriptionsIds: actualKidSubscriptionsIds,
                                        timeslots: timeslots,
                                    })
                                } else {
                                    setFormInStore(CreneauxDispo.formDataName, {
                                        ...formDataCreneau,
                                        timeslots: timeslots,
                                    })
                                }
                                this.setState({
                                    ajaxLoading: false,
                                })
                            },
                        )
                        break
                    }

                }
            }
        }

        const lastId = lastMesEnfants ?
            lastMesEnfants.selectedKid ?
                lastMesEnfants.selectedKid.id
                : undefined
            : undefined
        const newId = mesEnfants ?
            mesEnfants.selectedKid ?
                mesEnfants.selectedKid.id
                : undefined
            : undefined

        // Si un enfant est selectionné pour la première fois ou qu'il change on récupère ses inscriptions en cours
        if (
            (lastMesEnfants === undefined && mesEnfants !== undefined) ||
            (lastId !== newId) ||
            (choixEcole && lastChooseCenterAndType && lastChooseCenterAndType.centerId !== choixEcole.centerId)
        ) {
            setFormInStore(CreneauxDispo.formName, {
                ...formCreneau,
                selectedTimeslot: undefined,
                selectedTimeslotValue: undefined,
            })
            this.setState({
                ajaxLoading: true,
            })
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.canLoadTimeslots = true
            let getSubsMethod
            let getSubIdFromRegistration
            let getSubFromAnswer
            const periodStart = dayjs(new Date()).subtract(3, "year").format("YYYY-MM-DD")

            switch (tunnelType) {
            case "SCHOOL":
                getSubsMethod = () => getAllKidsSchoolsSubscriptions({
                    offset: 0,
                    orderByAsc: false,
                    periodStart: periodStart,
                    status: "[2,1,3]",
                })
                getSubIdFromRegistration = sub => sub.school.id
                break
            case "TRAINING":
                getSubsMethod = () => getAllKidsStageSubscriptions({
                    offset: 0,
                    orderByAsc: false,
                    periodStart: periodStart,
                    status: "[2,1,3]",
                })
                getSubIdFromRegistration = sub => sub.training.id
                getSubFromAnswer = sub => sub
                break
            }
            this.setState({
                ajaxLoading: true,
            })
            getSubsMethod().then(
                (data) => {
                    let subscriptions = data.items
                    let actualKidSubscriptionsIds = []
                    if (getSubFromAnswer) {
                        subscriptions = getSubFromAnswer(subscriptions)
                    }
                    if (actualSubscriptions) {
                        actualSubscriptions.forEach(
                            subscription => {
                                if (subscription.kid !== undefined) {
                                    if (subscription.kid.id === selectedKidId) {
                                        actualKidSubscriptionsIds.push(
                                            getSubIdFromRegistration(subscription),
                                        )
                                    }
                                }
                            },
                        )
                    }
                    this.setState({
                        actualSubscriptions: subscriptions,
                        ajaxLoading: false,
                    })
                    if (this.state.canLoadTimeslots) {
                        getCreneaux(actualKidSubscriptionsIds)
                    }
                },
            )
        } else {
            getCreneaux()
        }

        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.lastChooseCenterAndType = choixEcole
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.lastMesEnfants = mesEnfants
    }

    onTimeslotClick(event) {
        const {
            forms,
            setFormInStore,
            setSchoolSubscription,
            checkSkipStepEDFAction,
            tunnelType,
        } = this.props
        const formCreneau = forms[CreneauxDispo.formName]
        const formData = forms[CreneauxDispo.formDataName]
        const oldSelectedTimeslot = forms[CreneauxDispo.formName] !== undefined ?
            forms[CreneauxDispo.formName].selectedTimeslot
            :
            {}
        const ozmoSubscription = forms.ozmoSubscription

        let selectedTimeslot = formData.timeslots[event.currentTarget.id]
        let timeslotIdentifier
        switch (tunnelType) {
        case "SCHOOL":
            timeslotIdentifier = selectedTimeslot.value
            break
        case "TRAINING":
            timeslotIdentifier = selectedTimeslot.id
            break
        }

        if (this.state.isMobile) {
            this.closeAllTooltipDescription()

            const timeslot = document.querySelector("#description-card-" + timeslotIdentifier)
            if ((tunnelType === "TRAINING" && oldSelectedTimeslot && (oldSelectedTimeslot.id === selectedTimeslot.id))
                || (tunnelType === "SCHOOL" && oldSelectedTimeslot === selectedTimeslot)) {
                timeslot.classList.remove("active")
            } else {
                timeslot.classList.add("active")
            }
        }

        if ((tunnelType === "TRAINING" && oldSelectedTimeslot && (oldSelectedTimeslot.id === selectedTimeslot.id))
            || (tunnelType === "SCHOOL" && oldSelectedTimeslot === selectedTimeslot)) {
            selectedTimeslot = {}
            this.setState({
                selectedStageDetails: undefined,
            })

            if (tunnelType === "TRAINING") {
                setFormInStore(CreneauxDispo.formName, {
                    ...formCreneau,
                    selectedTimeslot: undefined,
                    selectedTimeslotValue: undefined,
                })
            }

        } else {
            /** STAGE : GETTING THE COMPLETE INFORMATIONS TO DISPLAY THE DESCRIPTION */
            if (tunnelType === "TRAINING") {
                this.setState({
                    ajaxLoading: true,
                })
                getKidsStageDetail(event.currentTarget.id).then(
                    (stage) => {
                        let stageDetails = stage.data.data
                        this.setState({
                            ajaxLoading: false,
                            selectedStageDetails: stageDetails,
                        })
                        setFormInStore(
                            "hasOption",
                            stageDetails.optionProducts.length !== 0,
                        )
                        setFormInStore(
                            "optionsProducts",
                            stageDetails.optionProducts,
                        )
                        setFormInStore(
                            "hasCatalogProducts",
                            stageDetails.catalogProducts.length !== 0,
                        )
                        setFormInStore(
                            NikeEquipment.formDataName,
                            {
                                availableEquipment: stageDetails.catalogProducts,
                            },
                        )

                        setFormInStore(CreneauxDispo.formName, {
                            ...formCreneau,
                            selectedTimeslot: stageDetails,
                            selectedTimeslotValue: timeslotIdentifier,
                        })
                    },
                )
            }
        }

        if (tunnelType === "TRAINING") {
            const isExistingSubs = ozmoSubscription && ozmoSubscription.firstSubscription
            if (isExistingSubs) {
                const subsToUpdate = ozmoSubscription.firstSubscription
                subsToUpdate.optionProducts = []
                subsToUpdate.catalogArticles = []

                setFormInStore(
                    "ozmoSubscription",
                    {
                        ...forms["ozmoSubscription"],
                        firstSubscription: subsToUpdate,
                    },
                )
            }
        }

        if (tunnelType === "SCHOOL") {
            setFormInStore(CreneauxDispo.formName, {
                ...formCreneau,
                selectedTimeslot: selectedTimeslot,
                selectedTimeslotValue: timeslotIdentifier,
            })
        }

        if (selectedTimeslot.schoolDetail !== undefined) {
            this.step.current.setRecap(
                <div>
                    {days[selectedTimeslot.day]}
                    <br />
                    {<HourDisplay minutes={selectedTimeslot.schoolDetails.hour} />}
                </div>,
            )
        }

        let schoolDetail = selectedTimeslot.schoolDetails

        this.setState({
            checkSkipStepEDF: checkSkipStepEDFAction(forms, selectedTimeslot).payload,
        })

        if (this.form.current) {
            this.form.current.validate()
        }
        if (schoolDetail !== undefined) {
            setSchoolSubscription(schoolDetail.id, schoolDetail.amount)
            if (schoolDetail.full || !schoolDetail.canRegister) {
                this.step?.current?.unvalidate()
            }
            else {
                this.step?.current?.validate()
            }
        }

        if (tunnelType === "TRAINING") {
            if (selectedTimeslot.full) {
                this.step?.current?.unvalidate()
            }
            else {
                this.step?.current?.validate()
            }
        }
    }

    subscribeToWaitingList() {
        const { forms, clearAllForms, goToStep, tunnelType, navigate } = this.props
        const enfants = forms[MesEnfants.formName]
        const selectedKid = enfants.selectedKid

        const formCreneau = forms[CreneauxDispo.formName]
        const dailySlot = formCreneau.selectedTimeslot

        this.setState({
            ajaxLoading: true,
        })
        getUser().then(
            (response) => {
                let userLoaded = response.data.data

                let data = {
                    child: selectedKid.id,
                    childBirthDate: selectedKid.birthDate.split("-").reverse().join("/"),
                    childFirstname: selectedKid.firstName,
                    childGender: selectedKid.gender,
                    childLastname: selectedKid.lastName,
                    childOutfitSize: selectedKid.equipment,
                    contact1Email: userLoaded.emailAddress,
                    contact1Firstname: userLoaded.firstname,
                    contact1Lastname: userLoaded.lastname,
                    contact1Phone: userLoaded.phoneNumber ? userLoaded.phoneNumber.replace("+33", "0") : "",
                    dailySlot: dailySlot.value ? dailySlot.value : dailySlot,
                    waitingForAvailability: true,
                }

                let handleReturn = (response) => {
                    this.setState({
                        ajaxLoading: false,
                    })

                    let confirmedSub
                    switch (tunnelType) {
                    case "SCHOOL":
                        confirmedSub = response.data
                        clearAllForms()
                        break
                    case "TRAINING":
                        confirmedSub = response.data.data
                        break
                    }

                    goToStep(0)
                    const lastUniverse = localStorage.getItem("lastUniverse")
                    const you = lastUniverse === "theme-padel" ? "te" : "vous"
                    const your = lastUniverse === "theme-padel" ? "ton" : "votre"
                    navigate(
                        "/confirmcommand", {
                            state: {
                                confirmedSubscription: confirmedSub,
                                message: `Un email ${you} sera envoyé en cas de places disponibles.`,
                                subtitle: `Nous ${you} confirmons l’inscription de ${your} enfant à la liste d’attente.`,
                                success: true,
                                title: "Inscription à la liste d’attente",
                                tunnelType: tunnelType,
                            },
                        },
                    )

                }
                switch (tunnelType) {
                case "SCHOOL":
                    editSchoolSubscription(data).then(handleReturn)
                    break
                case "TRAINING":
                    data.stageSession = dailySlot.id,
                    data.childPosition = selectedKid.position
                    editStageSubscription(data).then(handleReturn)
                    break
                }
            },
        )
    }

    ozmoRegistration() {
        const { tunnelType } = this.props
        return new Promise(
            (resolve) => {
                const { forms, setFormInStore, clearSchoolOptions, removeFormInStore, router } = this.props
                const { setSearchParams, searchParamsObject } = router
                const ozmoSubscription = forms.ozmoSubscription
                const enfants = forms[MesEnfants.formName]
                const selectedKid = enfants.selectedKid
                const formCreneau = forms[CreneauxDispo.formName]
                const dailySlot = formCreneau.selectedTimeslot

                const ozmoCall = () => {
                    this.setState({
                        ajaxLoading: true,
                    })

                    getUser().then(
                        (response) => {
                            let userLoaded = response.data.data
                            let data = {
                                child: selectedKid.id,
                                childBirthDate: selectedKid.birthDate.split("-").reverse().join("/"),
                                childFirstname: selectedKid.firstName,
                                childGender: genderValues[selectedKid.gender],
                                childLastname: selectedKid.lastName,
                                childOutfitSize: selectedKid.equipment,
                                contact1Email: userLoaded.emailAddress,
                                contact1Firstname: userLoaded.firstname,
                                contact1Lastname: userLoaded.lastname,
                                contact1Phone: userLoaded.phoneNumber ? userLoaded.phoneNumber.replace("+33", "0") : "",
                            }
                            switch (tunnelType) {
                            case "SCHOOL":
                                data.dailySlot = dailySlot.value

                                editSchoolSubscription(data).then(
                                    (response) => {
                                        const id = response.data.id
                                        setFormInStore(CreneauxDispo.formName, {
                                            ...formCreneau,
                                            schoolSubscriptionId: id,
                                        })

                                        setSearchParams({
                                            ...searchParamsObject,
                                            registrationId: id,
                                        })

                                        setFormInStore("ozmoSubscription", {
                                            ...forms["ozmoSubscription"],
                                            firstSubscription: response.data,
                                        })

                                        removeFormInStore("avoirFormData")

                                        if (response?.data?.id) {
                                            const data = response?.data

                                            const schoolDuration = dayjs(data?.school?.end).diff(dayjs(data?.school?.start), "days")

                                            window.dataLayer = window.dataLayer || []
                                            window.dataLayer.push({
                                                data: {
                                                    "cart_id": data?.id,
                                                    "product": data?.school?.name,
                                                    "product_brand": "Urban Soccer",
                                                    "product_category1": "Ecole de foot",
                                                    "product_category2": data?.school?.centerName,
                                                    "product_category3": data?.school?.name,
                                                    // // date format "sam. 07/08 - 10h00"
                                                    "product_category4": data?.school?.start ? dayjs(data?.school?.start).format("ddd DD/MM - HH[h]mm") : "",
                                                    "product_id": data?.school?.type,
                                                    "product_pricetaxfree": data?.proforma?.amountHT,
                                                    "product_pricetaxincluded": data?.proforma?.amountTTC,
                                                    "product_quantity": 1,
                                                    "product_variant": schoolDuration + " jours",
                                                },
                                                event: "product.add_to_cart",
                                            })
                                        }

                                        resolve()
                                    },
                                ).catch((e) => {
                                    if (e?.response?.data?.data?.Message) {
                                        ModalHandler.show(MessagePopin, {
                                            message: [
                                                e?.response?.data?.data?.Message,
                                                ...(e?.response?.data?.data?.Errors?.map(m => m.Message) ?? []),
                                            ].join(". "),
                                            title: "Erreur",
                                        })
                                    }
                                }).finally(() => this.setState({ ajaxLoading: false }))
                                break
                            case "TRAINING": {
                                data.stageSession = dailySlot.id,
                                data.childPosition = selectedKid.position

                                const optionProductsOfSubs = ozmoSubscription ?
                                    ozmoSubscription.firstSubscription.optionProducts : null
                                const equipmentsOfSubs = ozmoSubscription ?
                                    ozmoSubscription.firstSubscription.catalogArticles : null

                                editStageSubscription(data).then(
                                    (response) => {
                                        const id = response.data.data.id
                                        setFormInStore(
                                            CreneauxDispo.formName, {
                                                ...formCreneau,
                                                schoolSubscriptionId: id,
                                            },
                                        )

                                        setSearchParams({
                                            ...searchParamsObject,
                                            registrationId: id,
                                        })

                                        const subs = response.data.data
                                        if (optionProductsOfSubs) {
                                            subs.optionProducts = optionProductsOfSubs
                                        }
                                        if (equipmentsOfSubs) {
                                            subs.catalogArticles = equipmentsOfSubs
                                        }

                                        setFormInStore(
                                            "ozmoSubscription",
                                            {
                                                ...forms["ozmoSubscription"],
                                                firstSubscription: subs,
                                            },
                                        )

                                        removeFormInStore("avoirFormData")

                                        if (response?.data?.data?.id) {
                                            const data = response?.data?.data

                                            const schoolDuration = dayjs(data?.training?.end).diff(dayjs(data?.training?.start), "days")

                                            window.dataLayer = window.dataLayer || []
                                            window.dataLayer.push({
                                                data: {
                                                    "cart_id": data?.id,
                                                    "product": data?.training?.name,
                                                    "product_brand": "Urban Soccer",
                                                    "product_category1": "Stage de foot",
                                                    "product_category2": data?.training?.centerName,
                                                    "product_category3": data?.training?.name,
                                                    // // date format "sam. 07/08 - 10h00"
                                                    "product_category4": data?.training?.start ? dayjs(data?.training?.start).format("ddd DD/MM - HH[h]mm") : "",
                                                    "product_id": data?.training?.type,
                                                    "product_pricetaxfree": data?.proforma?.amountHT,
                                                    "product_pricetaxincluded": data?.proforma?.amountTTC,
                                                    "product_quantity": 1,
                                                    "product_variant": schoolDuration + " jours",
                                                },
                                                event: "product.add_to_cart",
                                            })
                                        }

                                        this.setState({
                                            ajaxLoading: false,
                                        })
                                        resolve()
                                    },
                                )
                                break
                            }

                            }
                        },
                    )
                }

                if (ozmoSubscription !== undefined) {
                    let { oldSelectedKid, selectedKid } = forms[MesEnfants.formName]
                    switch (tunnelType) {
                    case "SCHOOL":
                        if (ozmoSubscription.firstSubscription.school.id === dailySlot.value) {
                            if (oldSelectedKid) {
                                if (oldSelectedKid.equipment === selectedKid.equipment) {
                                    resolve()
                                    return
                                }
                            }
                        }
                        break

                    case "TRAINING":
                        if (ozmoSubscription.firstSubscription.training.id === dailySlot.id) {
                            if (oldSelectedKid) {
                                if (oldSelectedKid.equipment === selectedKid.equipment) {
                                    resolve()
                                }
                            }
                        }
                        break
                    }
                }
                clearSchoolOptions()
                ozmoCall()
            },
        )
    }

    handleMobileView() {
        const isMobile = (window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth) <= 640 ? true : false
        this.setState({
            ...this.state,
            isMobile: isMobile,
        })

        window.addEventListener("resize", () => {
            const isMobile = (window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth) <= 640 ? true : false
            this.setState({
                ...this.state,
                isMobile: isMobile,
            })
        })
    }

    renderDescriptionCard(timeslots, selectedTimeslotDetails) {
        const { tunnelType, forms } = this.props
        const form = forms[CreneauxDispo.formName] !== undefined ? forms[CreneauxDispo.formName] : {
            selectedTimeslot: {},
        }
        const selectedTimeslot = form.selectedTimeslot !== undefined ?
            form.selectedTimeslot
            :
            {}

        const entityIdentifier = tunnelType === "TRAINING" ? selectedTimeslot.id : selectedTimeslot.value

        if (selectedTimeslotDetails?.id !== entityIdentifier) {
            return null
        }

        return (
            timeslots ?
                Object.values(timeslots).length > 0 && (
                    <ReservationSlotInfoCard
                        reservationSlot={
                            tunnelType === "TRAINING" ?
                                selectedTimeslot
                                :
                                selectedTimeslotDetails
                        }
                        tunnelType={tunnelType}
                        subscribeToWaitingListCallback={this.subscribeToWaitingList}
                        slotName={tunnelType === "TRAINING" ? "stage" : "créneau"}
                        pleaseSelectText={
                            tunnelType === "TRAINING" ?
                                "une semaine de stage."
                                :
                                "le jour et le créneau de votre séance."
                        }
                    />
                )
                :
                <div></div>
        )
    }

    isEven(idx) {
        return idx % 2 === 0
    }

    closeAllTooltipDescription() {
        const tooltips = document.querySelectorAll(".tooltip-description")
        tooltips.forEach(t => {
            t.classList.remove("active")
        })
    }

    render() {
        const { forms, stepManagement, tunnelType } = this.props
        const { ajaxLoading, checkSkipStepEDF } = this.state
        const mesEnfants = forms[MesEnfants.formName] !== undefined ? forms[MesEnfants.formName] : { child: {} }
        const child = mesEnfants.selectedKid
        const formDataCreneau = forms[CreneauxDispo.formDataName]
        let actualKidSubscriptionsIds = []
        if (formDataCreneau !== undefined) {
            if (formDataCreneau.actualKidSubscriptionsIds !== undefined) {
                actualKidSubscriptionsIds = formDataCreneau.actualKidSubscriptionsIds
            }
        }

        const form = forms[CreneauxDispo.formName] !== undefined ? forms[CreneauxDispo.formName] : {
            selectedTimeslot: {},
        }

        const selectedTimeslot = form.selectedTimeslot !== undefined ?
            form.selectedTimeslot
            :
            {}

        let selectedTimeslotDetails = selectedTimeslot.schoolDetails !== undefined ?
            selectedTimeslot.schoolDetails
            :
            undefined

        if (tunnelType === "TRAINING") {
            selectedTimeslotDetails = selectedTimeslot
        }

        const help = <PhoneCallHelp tunnelType={tunnelType} />

        const validateStep = this.step.current ? () => {
            switch (tunnelType) {
            case "SCHOOL":
                if (selectedTimeslotDetails !== undefined) {
                    if (!selectedTimeslotDetails.full && selectedTimeslotDetails.canRegister) {
                        this.step?.current?.validate()
                    } else {
                        this.step?.current?.unvalidate()
                    }
                }
                break
            case "TRAINING":
                if (selectedTimeslot !== undefined && Object.keys(selectedTimeslot).length) {
                    if (selectedTimeslot.full === false) {
                        this.step?.current?.validate()
                    } else {
                        this.step?.current?.unvalidate()
                    }
                }
                break
            }
        } : () => { }
        const unvalidateStep = this.step.current ? this.step.current.unvalidate : () => { }

        const formData = formDataCreneau !== undefined ? formDataCreneau : {
            timeslots: undefined,
        }
        let { timeslots } = formData

        let sortedTimeslots
        switch (tunnelType) {
        case "SCHOOL":
            if (timeslots !== undefined) {
                sortedTimeslots = Object.values(timeslots).sort(TimeSort.sortByAscSchoolDay)
            }
            break
        case "TRAINING":
            if (timeslots !== undefined) {
                sortedTimeslots = Object.values(timeslots).sort(TimeSort.sortByAscStageStart)
            }
            break
        }

        let creneaux
        if (sortedTimeslots) {
            creneaux = []
            sortedTimeslots.forEach(
                (timeslot, idx) => {
                    switch (tunnelType) {
                    case "SCHOOL":
                        creneaux.push(
                            <div className="timeslots-div" key={timeslot.value}>
                                <CheckBlock
                                    callback={this.onTimeslotClick}
                                    checked={timeslot.value === selectedTimeslot.value}
                                    name="timeslots"
                                    value={timeslot.value}
                                    disabled={
                                        (!timeslot.schoolDetails.waitingListOpened && timeslot.schoolDetails.full)
                                            ||
                                            (actualKidSubscriptionsIds.includes(timeslot.schoolDetails.id))
                                    }
                                    key={timeslot.value}
                                    cls="edfCheckB"
                                >
                                    <div className="radioList__item__subTitle">
                                        {days[timeslot.schoolDetails.day]}
                                    </div>
                                    <div className="radioList__item__price">
                                        <HourDisplay minutes={timeslot.schoolDetails.hour} /> - <HourDisplay minutes={timeslot.schoolDetails.hour + timeslot.schoolDetails.rentalDuration} />
                                        {
                                            actualKidSubscriptionsIds.includes(timeslot.schoolDetails.id) ? (
                                                <strong>Déjà inscrit{child?.gender === "Fille" ? "e" : ""}</strong>
                                            ) : (
                                                timeslot.schoolDetails.full ? (
                                                    <strong>COMPLET</strong>
                                                ) : (
                                                    <>
                                                        <strong><CurrencyDisplay price={timeslot.schoolDetails.amountTotal ??  timeslot.schoolDetails.amount} /></strong>
                                                        {
                                                            timeslot.schoolDetails.remainingPlaces !== null &&
                                                                timeslot.schoolDetails.remainingPlaces !== 0 &&
                                                                timeslot.schoolDetails.remainingPlaces <= 3 && (
                                                                <span>
                                                                    {timeslot.schoolDetails.remainingPlaces}
                                                                    {timeslot.schoolDetails.remainingPlaces > 1 ? " places restantes" : " place restante"}
                                                                </span>
                                                            )
                                                        }
                                                    </>
                                                )
                                            )
                                        }
                                    </div>
                                </CheckBlock>
                            </div>,
                        )
                        break
                    case "TRAINING":
                        creneaux.push(
                            <div className="timeslots-div" key={timeslot.id}>
                                <CheckBlock
                                    callback={this.onTimeslotClick}
                                    checked={timeslot.id === selectedTimeslot.id}
                                    name="timeslots"
                                    value={timeslot.id}
                                    disabled={
                                        (
                                            !timeslot.waitingListOpened && timeslot.full
                                        )
                                            ||
                                            (
                                                actualKidSubscriptionsIds.includes(
                                                    timeslot.id,
                                                )
                                            )
                                    }
                                    key={timeslot.id}
                                    cls="sdfCheckB"
                                >
                                    <div className="radioList__item__subTitle">
                                        {timeslot.name}
                                    </div>
                                    <div className="radioList__item__price">
                                        {timeslot.nameBis}
                                        {
                                            actualKidSubscriptionsIds.includes(timeslot.id) ? (
                                                <strong>Déjà inscrit{child?.gender === "Fille" ? "e" : ""}</strong>
                                            ) : (
                                                timeslot.full ? (
                                                    <strong>COMPLET</strong>
                                                ) : (
                                                    <>
                                                        <strong><CurrencyDisplay price={timeslot.amount} /></strong>
                                                        {
                                                            timeslot.remainingPlaces !== null &&
                                                                timeslot.remainingPlaces !== 0 &&
                                                                timeslot.remainingPlaces <= 3 && (
                                                                <span>
                                                                    {timeslot.remainingPlaces}
                                                                    {timeslot.remainingPlaces > 1 ? " places restantes" : " place restante"}
                                                                </span>
                                                            )
                                                        }
                                                    </>
                                                )
                                            )
                                        }

                                    </div>
                                </CheckBlock>
                            </div>,
                        )
                        break
                    }

                    if (this.state.isMobile) {
                        if (idx === (sortedTimeslots.length - 1) || (!this.isEven(idx) && idx !== 0)) {
                            if (idx >= 1 && !this.isEven(idx)) {
                                const previousEl = sortedTimeslots[idx - 1]
                                let previousDetails
                                let previousId
                                switch (tunnelType) {
                                case "SCHOOL":
                                    previousDetails = previousEl.schoolDetails
                                    previousId = previousEl.value
                                    break
                                case "TRAINING":
                                    previousDetails = previousEl
                                    previousId = previousEl.id
                                    break
                                }
                                const isDisabled = (
                                    !previousDetails.waitingListOpened &&
                                    previousDetails.full
                                ) || actualKidSubscriptionsIds.includes(previousDetails.id)

                                if (!isDisabled) {
                                    creneaux.push(
                                        <div className="c-col c-col--3 c-col--sm--12 tooltip-description left"
                                            id={"description-card-" + previousId} key={"description-card-" + previousId}>
                                            {this.renderDescriptionCard(timeslots, previousDetails)}
                                        </div>,
                                    )
                                } else {
                                    <div className="c-col c-col--3 c-col--sm--12 tooltip-description left"></div>
                                }
                            }
                            let timeslotDetails
                            let timeslotId
                            switch (tunnelType) {
                            case "SCHOOL":
                                timeslotDetails = timeslot.schoolDetails
                                timeslotId = timeslot.value
                                break
                            case "TRAINING":
                                timeslotDetails = timeslot
                                timeslotId = timeslot.id
                                break
                            }
                            const isDisabled = (!timeslotDetails.waitingListOpened
                                && timeslotDetails.full) ||
                                (actualKidSubscriptionsIds.includes(timeslotDetails.id))
                            if (!isDisabled) {
                                let classToApply = "c-col c-col--3 c-col--sm--12 tooltip-description"
                                classToApply += (idx === 0 || this.isEven(idx)) ? " left" : ""
                                creneaux.push(
                                    <div className={classToApply}
                                        id={"description-card-" + timeslotId} key={"description-card-" + timeslotId}>
                                        {this.renderDescriptionCard(timeslots, timeslotDetails)}
                                    </div>,
                                )
                            } else {
                                <div className="c-col c-col--3 c-col--sm--12 tooltip-description left"></div>
                            }
                        }
                    }
                },
            )
        }

        return (
            <Step   {...this.props}
                title={
                    tunnelType === "TRAINING" ?
                        "Stages disponibles"
                        :
                        "Créneaux disponibles"
                }
                help={help}
                className="noMarginBottom slotSelection"
                helpId={"crenauxHelp"}
                ref={this.step}
                onValidation={this.ozmoRegistration}
                promise={true}
                goToStep={
                    tunnelType === "SCHOOL" ?
                        checkSkipStepEDF ?
                            checkSkipStepEDF.skipPrestaSupp ?
                                checkSkipStepEDF.skipEquipment ?
                                    stepManagement.currentStep + 3
                                    :
                                    stepManagement.currentStep + 2
                                :
                                null
                            :
                            null
                        :
                        null
                }
            >
                {ajaxLoading &&
                    <Preloader fixed={true} />
                }
                <div className="formsHolder c-row formsHolder justify-center align-start c-mrg--btm--4 c-mrg--btm--sm--1 layoutSmall">
                    <div className="c-col c-col--5 c-col--sm--12">
                        <FormValidator
                            onValidation={validateStep}
                            onUnvalidation={unvalidateStep}
                            ref={this.form}
                        >
                            <div className="radioList--small--half">
                                {creneaux && sortedTimeslots ?
                                    sortedTimeslots.length > 0 ?
                                        creneaux
                                        : (
                                            <div>
                                                {tunnelType === "SCHOOL" ?
                                                    "Toutes les écoles de football "
                                                    :
                                                    tunnelType === "TRAINING" ?
                                                        "Tous les stages de football "
                                                        :
                                                        "Not implemented TUNNELTYPE : " + tunnelType
                                                }
                                                correspondant à votre recherche sont compl
                                                {tunnelType === "SCHOOL" ?
                                                    "ètes"
                                                    :
                                                    tunnelType === "TRAINING" ?
                                                        "ets"
                                                        :
                                                        "UNKNOWN TUNNELTYPE"
                                                }
                                                , nous vous invitons à essayer un centre à proximité.
                                                <br /><br />
                                                N'hésitez pas à contacter votre centre pour plus d'informations.
                                            </div>
                                        )
                                    : (
                                        <Preloader fixed={true} />
                                    )}
                            </div>
                        </FormValidator>
                    </div>

                    {
                        !this.state.isMobile &&
                        (<div className="c-col c-col--3 c-col--sm--12 sticky">
                            {this.renderDescriptionCard(timeslots, selectedTimeslotDetails)}
                        </div>)
                    }
                </div>
            </Step>
        )
    }
}

CreneauxDispo.formName = "choixCreneau"
CreneauxDispo.formDataName = "creneauxData"

const mapStateToProps = state => {
    return {
        checkSkipStepEDF: state.checkSkipStepEDF,
        forms: state.forms,
        stepManagement: state.stepManagement,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        checkSkipStepEDFAction: (forms, selectedTimeslot) => dispatch(checkSkipStepEDF(forms, selectedTimeslot)),
        clearAllForms: () => dispatch(clearAllForms()),
        clearSchoolOptions: () => dispatch(clearSchoolOptions()),
        goToStep: (index) => dispatch(goToStep(index)),
        removeFormInStore: (name) => dispatch(removeFormEntry(name)),
        setFormInStore: (name, form) => dispatch(setFormEntry(name, form)),
        setSchoolSubscription: (id, amount) => dispatch(setSchoolSubscription(id, amount)),
    }
}

CreneauxDispo.propTypes = {
    checkSkipStepEDFAction: func,
    clearAllForms: func,
    clearSchoolOptions: func,
    forms: object,
    goToStep: func,
    navigate: oneOfType([ bool, func ]),
    removeFormInStore: func,
    router: object,
    setFormInStore: func,
    setSchoolSubscription: func,
    stepManagement: object,
    themeHeader: oneOfType([ bool, number ]),
    tunnelType: string,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreneauxDispo)
